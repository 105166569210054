import React, { useState } from 'react';
import data from './Data';
import SingleQuestion from './Question';
// import './Questions.css';
function QuestionsMain() {
  const [questions, setQuestions] = useState(data);
  return (
      <div className='containerQuestions'>
        <h3>FAQs</h3>
        <section className='info'>
          {questions.map((question) => {
            return (
              <SingleQuestion key={question.id} {...question}></SingleQuestion>
            );
          })}
        </section>
      </div>
  );
}

export default QuestionsMain;