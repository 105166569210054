import axios from "axios";
import React from "react";
const  WEBSITE_BASE_URL = "http://nsoftwebsiteenv-env.eba-bqjy3pqg.ap-south-1.elasticbeanstalk.com";
//  const  WEBSITE_BASE_URL = "http://localhost:8080";
// const WEBSITE_BASE_URL = "https://api.agify.io/?name=bella";
class Softurl extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
           
        }
    }
    async saveContactUsUrl(saveContact) {
        //  axios.post(WEBSITE_BASE_URL +'/contactus/save',saveContact).then(function(response){ console.log("firstName: "+response.data.firstName)});
        // axios.post(WEBSITE_BASE_URL +'/contactus/save',saveContact).then(response =>{alert(response.data.firstName)});
        // axios.post(WEBSITE_BASE_URL +'/contactus/save',saveContact).then(response =>{ this.setShow(true)});
        // var saveContact2 =JSON.stringify(saveContact);
        var responseContact = await axios.post(WEBSITE_BASE_URL+'/contactus/save',saveContact);
        console.log("jsondata ", JSON.stringify(responseContact.data.firstName));
        if (responseContact.status === 200) {
            alert("Hey "+responseContact.data.firstName+". Thanks for your query. My team getback to you soon...")
        }
        else {
            alert("Somthing went wrong please try after some time.")
        }
        console.log('responseContacCcompleted ' + responseContact.data.name)
        return responseContact.JSON;

    }
    async saveOrgData(orgRegistionData) {
        var responseOrgReg =  await axios.post(WEBSITE_BASE_URL+'/org/save',orgRegistionData);
        console.log("jsondata ", JSON.stringify(responseOrgReg));
        if (responseOrgReg.status === 200) {
            alert("Hey "+responseOrgReg.data.userName+". Thanks for your query. My team getback to you soon...")
        }
        else {
            alert("Somthing went wrong please try after some time.")
        }
        console.log('responseorgRegistionDatan ' + responseOrgReg)
        return responseOrgReg.JSON;
    }
}

export default new Softurl();
