import React from 'react'
import Navbar from '../components/navBar/Navbar';
import Footer from '../components/footer/Footer';
import Pagebody from '../components/mainPage/Pagebody';
import CreateContactUsComponent from '../components/contactUs/CreateContactUsComponent';

const Contact = () => {
  return (
    <div>
      <Navbar/>
      <Pagebody heading ="Contact."
       text="Email us with any questions or inquiries or call 91 9951651759/-91 7702552447. We would be happy to answer your questions and set up a meeting with you."/>
       
       <CreateContactUsComponent/>
      <Footer/>
    </div>
  )
}
export default Contact;