import { useState } from "react";
import './Registration.css';
import FormInput from '../login/FormInput';
import Footer from '../../components/footer/Footer';
import Navbar from '../../components/navBar/Navbar';
import Softurl from "../../api/Softurl";

const Registration = () => {
  const [values, setValues] = useState({
    userName: "",
    orgEmail: "",
    organizationName: "",
    password: "",
    confirmPassword: "",
    organizationRegNo:"",
    organizationRegDate:"",
    orgAddress:"",

  });

  const inputs = [
    {
      id: 1,
      name: "userName",
      type: "text",
      placeholder: "Username",
      errorMessage:
        "Username should be 3-16 characters and shouldn't include any special character!",
      label: "Username",
      pattern: "^[A-Za-z0-9]{3,16}$",
      required: true,
    },
    {
      id: 2,
      name: "orgEmail",
      type: "email",
      placeholder: "Email",
      errorMessage: "It should be a valid email address!",
      label: "Email",
      required: true,
    },
    {
      id: 3,
      name: "password",
      type: "password",
      placeholder: "Password",
      errorMessage:
        "Password should be 8-20 characters and include at least 1 letter, 1 number and 1 special character!",
      label: "Password",
      pattern: `^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$`,
      required: true,
    },
    {
      id: 4,
      name: "confirmPassword",
      type: "password",
      placeholder: "Confirm Password",
      errorMessage: "Passwords don't match!",
      label: "Confirm Password",
      pattern: values.password,
      required: true,
    },
    {
      id: 5,
      name: "organizationName",
      type: "text",
      placeholder: "Organization Name",
      label: "Organization Name",
      errorMessage:
      "rganization Name should be 3-16 characters and shouldn't include any special character!",
      required: true,
    },
    {
      id: 6,
      name: "organizationRegNo",
      type: "text",
      placeholder: "Organization Registration No.",
      label: "Organization Registration No.",
      required: true,
    },
    {
      id: 7,
      name: "organizationRegDate",
      type: "date",
      placeholder: "Organization Registration Date",
      label: "Organization Registration Date",
      required: true,
    },
    {
      id: 8,
      name: "orgAddress",
      type: "text",
      placeholder: "Org Address",
      label: "Org Address",
      required: true,
    },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    let orgRegistionData ={userName:values.userName,orgEmail:values.orgEmail,password:values.password,confirmPassword:values.confirmPassword,organizationName:values.organizationName,organizationRegNo:values.organizationRegNo,organizationRegDate:values.organizationRegDate,orgAddress:values.orgAddress}
    console.log(orgRegistionData)
    Softurl.saveOrgData(orgRegistionData).then(responseData =>{console.log("success")}).catch(err => {console.log(err)})
  };

  function onChange(e) {
    setValues({ ...values, [e.target.name]: e.target.value });
  }

  return (
    <div>
    <Navbar/>
    <div className="app">
      <form onSubmit={handleSubmit}>
        <h1>Register</h1>
        {inputs.map((input) => (
          <FormInput
            key={input.id}
            {...input}
            value={values[input.name]}
            onChange={onChange}
          />
        ))}
        <button className="btn">Submit</button>
      </form>
    </div>
    <Footer/>
    </div>
  );
};

export default Registration;