import "./Footer.css";
import React from 'react'
import { FaHome, FaPhone, FaMailBulk, FaFacebook, FaTwitter, FaLinkedin, FaInstagram } from "react-icons/fa";


const Footer = () => {
    return (
        <div className="footer">
            <div className="sb__footer section__padding">
                <div className="sb__footer-links">
                     <div className="sb__footer-links_div">
                        <h1>Office Address</h1>
                        <div className="location">
                            <FaHome size={20} style={{ color: "#fff", marginRight: "2rem" }} />
                            <p>H.N. 4-2-12-288,Road No.1,</p>
                        </div>
                        <div>
                            <p>         Hight Court Coloy,Vanasthalipuram,Hyderaad -500070. T.S</p>
                        </div>
                        <div className="phone">
                            <p>
                                <FaPhone size={20} style={{ color: "#fff", marginRight: "2rem" }} />
                                +91 9951651759 / +91 7702552447
                            </p>
                        </div>
                        <div className="email">
                            <p>
                                <FaMailBulk size={20} style={{ color: "#fff", marginRight: "2rem" }} />
                                nsoftsolution@outlook.com
                            </p>
                        </div>

                    </div> 
                    <div className="sb__footer-links_div">
                        <h1>Need Help</h1>
                        <a href="/contact">
                            <p>Location</p>
                        </a>
                        <a href="/contact">
                            <p>About US</p>
                        </a>
                        <a href="/contact">
                            <p>Contact US</p>
                        </a>
                        <a href="/contact">
                            <p>Location</p>
                        </a>
                        <a href="/contact">
                            <p>FAQ's</p>
                        </a>

                    </div>
                    {/* <div className="sb__footer-links_div">
                        <h1>NSoft</h1>
                        <a href="/abc">
                            <p>Hyderabad</p>
                        </a>
                        <a href="/xyz">
                            <p>Yadadri</p>
                        </a>
                        <a href="/xyz">
                            <p>Amalapuram</p>
                        </a>
                        <a href="/xyz">
                            <p>Nellore</p>
                        </a>
                        <a href="/xyz">
                            <p>Rajamandry</p>
                        </a>
                    </div> */}
                    <div className="sb__footer-links_div">
                        <h1> About company</h1>
                         <p>Nsoft Solutions. Make work life simpler, more pleasant and more productive.</p> 
                        <FaFacebook size={20} style={{ color: "#fff", marginRight: "1rem" }} />
                        <FaTwitter size={20} style={{ color: "#fff", marginRight: "1rem" }} />
                        <FaLinkedin size={20} style={{ color: "#fff", marginRight: "1rem" }} />
                        <FaInstagram size={20} style={{ color: "#fff", marginRight: "1rem" }} />
                    </div>
                </div>
                <hr></hr>
                <div className="sb__footer-below">
                    <div className="sb__footer-copyright">
                        <p>
                            All rights reserved by NSoft Solutions. Copyright © 2023
                        </p>

                    </div>
                    <div className="sb__footer-below-links">
                        <a href="/tearms" ><div><p>Tearms and conditions</p></div></a>
                        <a href="/privacy" ><div><p>Privacy</p></div></a>
                        <a href="/securty" ><div><p>Security</p></div></a>

                    </div>

                </div>
            </div>
        </div>
    )
}

export default Footer;