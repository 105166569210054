const Data = [
    {
      id: 1,
      title: 'What are the main elements of a school ERP system?',
      info:
        'A school ERP system is simply an ERP system that has been specifically tailored to be used in the context of a school. Therefore, the elements of an ERP for schools will include solutions such as Fee Management software, admission management system, library management system, hostel management system, exam management system, and so on.',
    },
    {
      id: 2,
      title: 'What are the factors to consider when choosing an ERP system?',
      info:
        'The main factors to consider when choosing an ERP system are its ability to multitask, usability across different platforms, and flexibility with regard to its use cases. Nsoft’s elaborate school management ERP improves the overall efficiency of the school & each individual stakeholder.',
    },
    {
      id: 3,
      title: 'Can ERP improve student performance?',
      info:
        'The best school ERP software solutions on the market are the ones that have offerings that cater to all stakeholders. Nsoft allows students to assess themselves by giving them detailed performance analytics on a regular basis. It also helps them know how much fees is left to be paid, by how much there is an attendance shortage (in case of any), and even their school bus stop & bus fees (if they use the facility).',
    },
    {
      id: 4,
      title: 'What are the benefits of using School ERP software?',
      info:
        'Using school ERP software helps teachers and administrators improve their efficiency, manage the institute effortlessly, and keep a track of all ongoing activities. Moreover, digitization reduces the risk of manual errors and helps in the proper functioning of schools. With ERP for schools, admins can track every activity through in-depth reports and analytics and an interactive dashboard.',
    },
    {
      id: 5,
      title: 'What are the different modules included in ERP for school software?',
      info:
        'School Enterprise Resource Planning software by Nsoft includes modules like fee management, admission management, attendance management, certificate generator, transport management, inventory management, ID card generator, website builder, and more.',
    },
	{
		id:6,
		title:'How can School Enterprise Resource Planning software improve school efficiency?',
	info:'The ERP for school improves school efficiency as teachers and admins can take care of all academic and non-academic activities through a single platform, thus reducing the time spent on managing all tasks manually. Through this system, students can monitor their performance hassle-free and parents can remain involved in school activities.'
	}
  ]
  export default Data