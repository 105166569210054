import React from "react";
import "./SignIn.css";
import Footer from '../../components/footer/Footer';
import Navbar from '../../components/navBar/Navbar';
import { Link } from "react-router-dom";

const SignIn = () => {
    return(
        <div>
            <Navbar/>
            <form className="signForm" onsb>
                 <h1> <center>Sign In </center></h1>
                <input type='email' name="emailId" placeholder="Enter Email Id" required />
                <input type='password' name="password" placeholder="Enter Password" required />
                <label className="inputLabel"> <input type='checkbox' id="checkid" name="vehicle1" value="Bike" /> Remember me </label>
                <button className="btn"> Sign In</button>
                <br></br>
                <h4><center>OR</center></h4>
            </form>
            <form>
            <Link to="/signup" className="btn">Sign Up</Link>
            </form>
            <Footer/>
        </div>
    )


}
export default  SignIn;