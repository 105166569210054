import "./Navbar.css";

import React, { useState } from 'react'
import { Link } from "react-router-dom";
import {FaBars,FaTimes} from "react-icons/fa";
import Logo from '../../Assets/logo.jpg';


const Navbar = () => {
    const [click, setClick] = useState(false);
    const handleClick = () => setClick(!click);
  
    const [color, setColor] = useState(false);
    const changeColor =() =>{
      if(window.scrollY >=100){
        setColor(true);
      } else{
        setColor(false);
      }
    };
    window.addEventListener("scroll", changeColor);

  return (
    <div className={color ? "header header-bg": "header"}>
        <Link to={"/"}> <img  className="logoImg" src={Logo} alt='Nsoft Logo'/></Link>
        <ul className={ click ? "nav-menu active":"nav-menu"}>
            <li><Link to ="/">Home</Link> </li>
            <li><Link to ="/service">Service</Link> </li>
            <li><Link to ="/contact">Contact</Link> </li>
            <li><Link to ="/about">About</Link> </li>
            <li><Link to ="/signin">SignIn/SignUp</Link> </li>
        </ul>
        <div className="hamburger" onClick={handleClick}>
            {click ? (<FaTimes size={20} style={{color:"#fff"}}/>)
            :(<FaBars size={20} style={{color:"#fff"}}/>) }

        </div>
    </div>
  )
}

export default Navbar;