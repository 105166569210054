import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import  './Benefits.css'

function Benefits() {
    const benifitsCard = [
        {
            name: 'Narendar Kogari',
            description: ' he has been an entrepreneur, Narendar and his team launched NSoft, which has transformed business communication.',
            address: 'CEO & Co-founder',
            img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/e/ee/Indian_Rupee_symbol.svg/1200px-Indian_Rupee_symbol.svg.png'
        },
        {
            name: 'Narendar Kogari',
            description: 'Narendar is the CEO and Co-founder of NSoft. For more than 6 years, he has been an entrepreneur, designer and technology leader, usually all at once. In 2016, Narendar and his team launched NSoft, which has transformed business communication.',
            address: 'CEO & Co-founder',
            img: 'https://github.com/Rameshnaari/Rameshnaari/blob/main/Nsoft/nariImg.jpg?raw=true'
        },
        {
            name: 'Narendar Kogari',
            description: 'Narendar is the CEO and Co-founder of NSoft. ForNarendar is the CEO and Co-founder of NSoft. For more than 6 years, he has been an entrepreneur, designer and technology leader, usually all at once. In 2016, Narendar and his team launched NSoft, which has transformed business communication.',
            address: 'CEO & Co-founder',
            img: 'https://github.com/Rameshnaari/Rameshnaari/blob/main/Nsoft/nariImg.jpg?raw=true'
        }
    ]
  return (
    
    <Row className='benefits'>
        {/* <title> test</title> */}
     { benifitsCard.map((benifitsCardD, indx) => (
        <Col>
          <Card className='textSytle'>
            <Card.Img variant='top' src={benifitsCardD.img} className='cardImg'/>
            <Card.Body>
              <Card.Title>{benifitsCardD.name}</Card.Title>
              <Card.Text >
                {benifitsCardD.description}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      ))}
    </Row>
  );
}

export default Benefits;