import "./AboutContent.css"

import React from 'react'
import aboutImage from "../../Assets/aboutPage.jpg"
import aboutImage2 from "../../Assets/aboutPage2.jpg"
import { Link} from "react-router-dom"

const AboutContent = () => {
  return (
    <div className="about">
        <div className="left">
            <h1>WHO ARE WE?</h1>
            <p> We are here to hlelp you to develop the application  </p>
            <Link to="/contact">
                <button className="btn" >Contact</button>
            </Link>
         </div>
    {/* <div className="right">
        <div className=" img-container">
            <div className="img-stack top" >
                <img src={aboutImage} className="img" alt="Ramesh nari"/> 
            </div>

        </div>
    </div>
            <div className="img-stack bottom" >
                <img src={aboutImage2} className="img" alt="Ramesh nari about "/> 
            </div> */}
            
   </div>
    
  )
}

export default AboutContent