import React from "react";
import { MdDesignServices } from "react-icons/md";
import { FiCodesandbox } from "react-icons/fi";
import { CgWebsite } from "react-icons/cg";
import styled from "styled-components";
import Card from "./Card";
// import { Slide } from "react-awesome-reveal";

const BenefitsSec = () => {
  return (
    <Container id="service">
        <h4>
          School Application Benefits{/* My <span className="green">services</span> */}
        </h4>
        {/* <h1>What I Do</h1> */}
      <Cards>
          <Card
            Icon={MdDesignServices}
            title={"Fee Management"}
            disc={`Customize and simplify the process of fee collection, segregation, management, and issuing discounts as and when required`}
          />
          <Card
            Icon={FiCodesandbox}
            title={"Attendance Management"}
            disc={`Manage school attendance without taking up precious class time with an effective attendance management system in place`}
          />
          <Card
            Icon={CgWebsite}
            title={"Admission Management"}
            disc={`Track student inquiries offline & online, track potential leads at every stage, and create an admission portal with zero coding required`}
          />
          <Card
            Icon={CgWebsite}
            title={"Communication Module"}
            disc={`Send out announcements, conduct relevant polls, and conduct surveys`}
          />
          <Card
            Icon={CgWebsite}
            title={"Exam Management"}
            disc={`Schedule & plan exams for the entire year and print report cards seamlessly with minimal human involvement`}
          />
          <Card
            Icon={CgWebsite}
            title={"Certificate Generator"}
            disc={`Generate certificates & documents for students and staff, tweak the design as per requirement, and even design them from scratch`}
          />
      </Cards>
    </Container>
  );
};

export default BenefitsSec;

const Container = styled.div`
  width: 90%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 3rem 0;
  @media (max-width: 840px) {
    width: 90%;
  }

  h1 {
    padding-top: 1rem;
  }
`;
const Cards = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  margin-top: 4rem;
  gap: 1rem;
`;