import React from 'react';
import Navbar from "../components/navBar/Navbar";
import Footer from "../components/footer/Footer";
import Pagebody from "../components/mainPage/Pagebody";

export const Project = () => {
  return (
    <div>
      <Navbar/>
      <Pagebody heading ="SERVICES." text="You can do everything yourself, but you don’t have to. We offer a range of services to make life a little easier for you. And we’ve taken action to ensure our services are safe for our customers, co-workers and service providers. You’ll see some temporary changes to our services as. A School management system is a complete educational ERP solution that streamlines, automates, and simplifies day-to-day operations. We help schools manage online admissions, daily attendance, homework, assessments, examinations, fee collections, transportation, HR payroll management, hostel and library management. A school ERP provides various modules to digitise campus administration and enable educators to make quick decisions that enhance student and staff performance promoting the institution’s growth."/>
      <Footer/>
    </div>
    
  )
}
export default Project;