import TestiMonialsDetails from './TestiMonialsDetails'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './TestiMonials.css'

const TestiMonials = () => {
  
    const testiMonials = [
        {
            name: 'Narendar Kogari',
            description: 'Narendar is the CEO and Co-founder of NSoft. For more than 6 years, he has been an entrepreneur, designer and technology leader, usually all at once. In 2016, Narendar and his team launched NSoft, which has transformed business communication.',
            address: 'CEO & Co-founder',
            img: 'https://github.com/Rameshnaari/Rameshnaari/blob/main/Nsoft/nariImg.jpg?raw=true'
        },
        {
            name: 'Ramesh Nari',
            description: 'Ramesh Nari is the Co-founder and Chief Technology Officer of NSoft. He oversees NSoft world-class engineering team and sets the technical vision for the company. And product strategy and development, as well as design and research.',
            address: 'Co-founder & CTO',
            img: 'https://github.com/Rameshnaari/Rameshnaari/blob/main/Nsoft/ramiImg5.jpg?raw=true'
        },
        {
            name: 'Madhukar',
            description: 'Develop high quality business strategies and plans ensuring their alignment with short-term and long-term objectives.Enforce adherence to legal guidelines and in-house policies to maintain the company’s legality and business ethics',
            address: 'Chief Product Officer',
            img: 'https://github.com/Rameshnaari/Rameshnaari/blob/main/Nsoft/Madhu.jpg?raw=true'
        },
        
    ]
    //Owl Carousel Settings
    const options = {
        loop: true,
        center: true,
        items: 3,
        margin: 0,
        autoplay: true,
        dots: true,
        autoplayTimeout: 8500,
        smartSpeed: 450,
        nav: false,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 3
            },
            1000: {
                items: 3
            }
        }
    };
    return (
        <section id="testimonial" className='testimonials pt-70 pb-70'>
            <div className='container mt-5'>
               <div className='row'>
                    <div className='col-md-12'>
                        <OwlCarousel id="customer-testimonoals" className='owl-carousel owl-theme' {...options}>
                            {
                                testiMonials.length === 0 ?
                                    <div className='item'>
                                        <div className='testimonial-name'>
                                            <h5>Rajon Rony</h5>
                                            <small>ITALY</small>
                                        </div>
                                    </div> :
                                    testiMonials.map((testiMonialDetail,index) => {
                                        return (
                                            <TestiMonialsDetails key={index} testiMonialDetail={testiMonialDetail}  />

                                        )
                                    })
                            }
                        </OwlCarousel>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default TestiMonials;