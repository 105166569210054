import React from 'react'
import Navbar from '../components/navBar/Navbar';
import Footer from '../components/footer/Footer';
import Pagebody from '../components/mainPage/Pagebody';
import AboutContent from '../components/aboutPage/AboutContent';

const About = () => {
  return (
    <div>
      <Navbar/>
      <Pagebody heading ="ABOUT."
       text="Nsoft Solutions. Make work life simpler, more pleasant and more productive."/>
      <AboutContent/>
      <Footer/>

    </div>
  )
}

export default About