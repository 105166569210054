import React from 'react';
import { ThemeProvider } from 'styled-components';
import { Section, SectionTitle, Boxes, Box, BoxNum, BoxText } from './AcomplishmentsStyles';
// import BackgroundAnimation from '../BackgrooundAnimation/BackgroundAnimation';
const theme = {

  breakpoints: {
    sm: 'screen and (max-width: 640px)',
    md: 'screen and (max-width: 768px)',
    lg: 'screen and (max-width: 1024px)',
    xl: 'screen and (max-width: 1280px)'
  },
}
const data = [
  { number: 7, text: 'Years Experience' },
  { number: 40, text: 'Clients', },
  { number: 10, text: 'Distinct Projects', },
  { number: 40000, text: 'Users', }
];

const Acomplishments = () => (
  
  <ThemeProvider theme={theme}>
    <Section>
      <SectionTitle>Company Achievements</SectionTitle>
      <Boxes>
        {data.map((card, index) => (
          <Box key={index}>
            <BoxNum>{`${card.number}+`}</BoxNum>
            <BoxText>{card.text}</BoxText>
          </Box>
        ))}
      </Boxes>
    </Section>
  </ThemeProvider>
);

export default Acomplishments;
