import React, { useEffect, useRef } from 'react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';

const MapContainer = (props) => {
  const mapRef = useRef(null);

  useEffect(() => {
    const map = new window.google.maps.Map(mapRef.current, {
      zoom: 12,
      center: {
        lat: props.lat,
        lng: props.lng
      }
    });

    new window.google.maps.Marker({
      position: { lat: props.lat, lng: props.lng },
      map: map
    });
  }, []);

  return (
    <Map
      ref={mapRef}
      google={props.google}
      zoom={14}
      initialCenter={{ lat: props.lat, lng: props.lng }}
    />
  );
};

export default GoogleApiWrapper({
  apiKey: 'YOUR_API_KEY_HERE'
})(MapContainer);
