import React from "react";
import "./index.css";
import Home from "./routes/Home";
import Service from "./routes/Service";
import Contact from "./routes/Contact";
import About from "./routes/About";
import SignIn from "./components/login/SignIn";
import {Route, Routes } from "react-router-dom";
import Registration from "./components/login/Registration";
import ScrollToTop from "./components/util/ScrollToTop";

function App() {
  return (
    <div>
      <ScrollToTop />
      <Routes>
        
        <Route path ="/" element = {<Home />}/>
        <Route path ="/service" element = {<Service />}/>
        <Route path ="/contact" element = {<Contact />}/>
        <Route path ="/about" element = {<About />}/>
        <Route path ="/signin" element = {<SignIn />}/>
        <Route path ="/signup" element = {<Registration />}/>
      </Routes>
</div>
  );
}

export default App;
