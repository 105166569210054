import React from 'react'
import Navbar from '../components/navBar/Navbar';
import Homebody from '../components/mainPage/Homebody';
import Footer from '../components/footer/Footer';
import TestiMonials from '../components/mainPage/TestiMonials';
import Acomplishments from '../components/Acomplishments/Acomplishments';
import School from '../components/school/School';
import Benefits from '../components/benefits/Benefits';
import QuestionsMain from '../components/question/QuestionsMain';
import BenefitsSec from '../components/benefits/BenefitsSec';
import Marquees from '../components/marquee/Marquees';
// import FeedBackWords from '../components/FeedBack/FeedBackWords';
// import BackgroundAnimation from '../components/BackgrooundAnimation/BackgroundAnimation';

const Home = () => {
  return (
    <div>
        <Navbar />
        <Homebody />
        <School/>
      <div>
        {/* <BackgroundAnimation /> */}
      </div>
      <br></br>
      <div>
        <TestiMonials />
      </div>
      <div>
        <Acomplishments />
      </div>
      <BenefitsSec/>
      <Marquees/>
      <div>
        {/* <FeedBackWords /> */}
      </div>
      <QuestionsMain/>
      <div>
        <Footer />
      </div>
    </div>
  )
}

export default Home;