import "./Homebody.css";
import React from 'react'
import homeImg from "../../Assets/homeImg2.jpg";
import { Link } from "react-router-dom";

export const Homebody = () => {
  return (
    <div className="hbody">
      <div className="mask">
        <img className="into-img"
          src={homeImg} alt="IntroImg" />
      </div>
      <div className="content">
        <h1> We are here for</h1>
        <h4> Helping educational institutions automate everyday operations with complete enterprise solutions.life a little easier for you.</h4>
        <div>
          <Link to="./service" className="btn">Service</Link>
          <Link to="./contact" className="btn btn-light"> Contact</Link>
        </div>
      </div>
    </div>

  )

}
export default Homebody;