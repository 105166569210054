import "./Form.css";
import React, { Component } from 'react'
import Softurl from "../../api/Softurl";
import MapContainer from "./MapContainer";
// import { Container, Alert } from 'react-bootstrap'

export class CreateContactUsComponent extends Component {

  constructor(props) {
    super(props)

    this.state = {
      firstName: "",
      emailId: "",
      mobileNo: "",
      subject: "",
      message: "",
      test: ""
    }
    this.state = {
      show: false
    }
    this.changeFirstNameHandler = this.changeFirstNameHandler.bind(this);
    this.changeEmailIdHandler = this.changeEmailIdHandler.bind(this);
    this.changeMobileNoHandler = this.changeMobileNoHandler.bind(this);
    this.changeSubjectHandler = this.changeSubjectHandler.bind(this);
    this.saveContactUs = this.saveContactUs.bind(this)
  }
  saveContactUs = (event) => {
    event.preventDefault();
    var sasc = ''
    let saveContact = { firstName: this.state.firstName, emailId: this.state.emailId, mobileNo: this.state.mobileNo, subject: this.state.subject, message: this.state.message }
    console.log(saveContact)
    sasc = Softurl.saveContactUsUrl(saveContact).then(responseData => {
      this.setState({
        test: responseData.data
      })
    }).catch(
      err => {
        console.log(err)
      }
    )
    console.log('final values ', sasc)
  }
  changeFirstNameHandler = (event) => {
    this.setState({ firstName: event.target.value });
  }
  changeEmailIdHandler = (event) => {
    this.setState({ emailId: event.target.value });
  }
  changeMobileNoHandler = (event) => {
    this.setState({ mobileNo: event.target.value });
  }
  changeSubjectHandler = (event) => {
    this.setState({ subject: event.target.value });
  }
  changeMessageHandler = (event) => {
    this.setState({ message: event.target.value });
  }

  render() {
    return (
      <div>
        <div>
          <div className="form">
            <form>
              <label className="inputLabel"> Your Name</label>
              <input type="text" placeholder="Your Name" className="form-control" name="firstName" value={this.state.firstName} onChange={this.changeFirstNameHandler}></input>
              <label className="inputLabel">Email</label>
              <input type="emailId" placeholder="email" className="form-control" name="emailId" value={this.state.emailId} onChange={this.changeEmailIdHandler}></input>
              <label className="inputLabel">Mobile Number</label>
              <input type="mobileNo" placeholder="mobile No" className="form-control" name="mobileNo" value={this.state.mobileNo} onChange={this.changeMobileNoHandler}></input>
              <label className="inputLabel"> Subject</label>
              <input type="text" placeholder="Subject" className="form-control" name="subject" value={this.state.subject} onChange={this.changeSubjectHandler}></input>
              <label className="inputLabel"> Message</label>
              <textarea row="6" placeholder="Enter your message here" className="form-control" name="message" value={this.state.message} onChange={this.changeMessageHandler}></textarea>
              <button className="btn" onClick={this.saveContactUs}>Submit</button>
            </form>
          </div>
        </div>
        <div>
          <div className="mapSection">
            <iframe title="Nsoft office Location" id="gmap_canvas" style={{ border: "0" }} loading="lazy" src="https://maps.google.com/maps?q=17%C2%B019'56.3%22N%2078%C2%B035'09.3%22E&t=&z=13&ie=UTF8&iwloc=&output=embed" scrolling="no" />
          {/* </div>

          <MapContainer
            lat={37.7749}
            lng={-122.4194}
          /><div> */}

          </div>
        </div>
      </div>
    )
  }
}

export default CreateContactUsComponent;