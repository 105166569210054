import "./Pagebody.css";

import React, { Component } from 'react'

class Pagebody extends Component{
  render(){
  return (
    <div className="pagebody-img">
        <div className="heading">
            <h1>{this.props.heading} </h1>
            <p className="text-center"> {this.props.text}</p>
        </div>
    </div>
  );
}
}
export default Pagebody;